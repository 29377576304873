@import '../../assets/style/variables.module.scss';

.projects {
  background: $--global-grey;
  display: flex;
  flex-direction: column;
  align-items: center;

  .paginationWrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    padding: 33px 0;

    .prevWrapper,
    .nextWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      font-family: $--Sohne-Breit-Halbfett;
      color: $--dark-blue;
      font-size: 18px;
      cursor: pointer;
      background: transparent;
      svg {
        path,
        rect {
          fill: $--dark-blue;
        }
      }
    }

    .prevWrapper {
      svg {
        transform: rotate(180deg);
      }
    }

    .disabled {
      color: rgba($color: $--text-color, $alpha: 0.55);

      svg {
        path,
        rect {
          fill: rgba($color: #909090, $alpha: 1);
        }
      }
    }

    .numberWrapper {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        font-family: $--Sohne-Breit-Buch;
        font-size: 24px;
        color: rgba($color: $--text-color, $alpha: 0.55);
        cursor: pointer;
        background: transparent;
      }

      .active {
        font-family: $--Sohne-Breit-Halbfett;
        color: $--dark-blue;
      }
    }
  }

  .projectWrapper {
    width: 100%;
    max-width: $--max-width;
  }

  h1 {
    font-family: $--Sohne-Leicht;
    width: 100%;
    font-size: 90px;
    color: #424242;
    margin-top: 50px;
    padding-left: 15px;
    box-sizing: border-box;
    margin-bottom: 60px;
  }
}

.projectCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
  border-bottom: 1px solid black;

  .info {
    a {
      font-family: $--Sohne-Halbfett;
      color: #424242;
      font-size: 18px;
      display: flex;
      align-items: center;
      gap: 15px;

      span {
        margin-top: 16px;
      }
    }

    h3 {
      font-family: $--Sohne-Buch;
      font-size: 40px;
      max-width: 310px;
      color: #424242;
      margin-top: 15px;
      margin-bottom: 20px;
    }

    h4 {
      font-family: $--Sohne-Breit-Halbfett;
      font-size: 18px;
      color: #424242;

      .project {
        position: relative;
        margin-right: 20px;
        color: $--dark-blue;
        &::before {
          content: '';
          position: absolute;
          width: 1px;
          height: 100%;
          background: black;
          right: -10px;
        }
      }

      .mural {
        font-family: $--Sohne-Breit-Buch;
        color: rgba(66, 66, 66, 0.55);
      }

      svg {
        margin: 0 8px;
        path {
          stroke: $--dark-blue;
        }
      }
    }
  }

  img {
    width: 60%;
    height: 185px;
    object-fit: cover;
    object-position: center;
  }
}

@media only screen and (max-width: 900px) {
  .projects {
    h1 {
      width: 100%;
      font-size: 48px;
      font-family: $--Sohne-Leicht;
    }

    .projectWrapper {
      width: 90%;
    }

    .paginationWrapper {
      align-items: flex-start;
      width: 90%;
      .prevWrapper,
      .nextWrapper {
        flex-direction: column;
        svg {
          width: 76px;
          height: 76px;
        }

        h6 {
          font-size: 12px;
        }
      }

      .nextWrapper {
        flex-direction: column-reverse;
      }

      .numberWrapper {
        button {
          font-size: 18px;
        }
      }
    }
  }

  .projectCard {
    flex-direction: column;

    .info {
      width: 100%;
      h4 {
        font-size: 12px;

        .project,
        .mural {
          font-size: 12px;
        }
      }

      h3 {
        font-size: 24px;
        max-width: 210px;
      }

      a {
        span {
          font-size: 12px;
          color: #4c2dc1;
        }
      }
    }

    img {
      width: 100%;
      height: 130px;
      margin-top: 20px;
    }
  }
}
