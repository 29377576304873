@import '../../assets/style/variables.module.scss';

.artistDetail {
  background: $--global-grey;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .mainTitle {
    font-family: $--Sohne-Leicht;
    width: 100%;
    font-size: 90px;
    color: #424242;
    margin-top: 50px;
    padding-left: 15px;
    box-sizing: border-box;
    margin-bottom: 60px;
  }

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 36px;
    position: relative;
    border-bottom: 1px solid $--text-color;

    h1 {
      font-size: 62px;
      color: $--text-color;
      font-family: $--Sohne-Buch;
    }

    .subInfo {
      position: absolute;
      left: 0;
      display: flex;
      gap: 10px;
      align-items: center;

      a {
        font-family: $--Sohne-Breit-Halbfett;
        font-size: 18px;
        color: $--dark-blue;
      }
      h5 {
        font-family: $--Sohne-Breit-Buch;
        color: rgba($color: $--text-color, $alpha: 0.55);
      }

      .divider {
        height: 30px;
        width: 1px;
        background: black;
      }
    }

    .rightInfo {
      position: absolute;
      right: 0;
      display: flex;
      gap: 5px;
      align-items: flex-end;
      flex-direction: column;

      h3 {
        font-family: $--Sohne-Buch;
        font-size: 18px;
        color: rgba($color: $--text-color, $alpha: 0.55);

        span {
          font-family: $--Sohne-Halbfett;
          color: $--text-color;
        }
      }
      a {
        font-family: $--Sohne-Buch;
        color: $--dark-blue;
        font-size: 18px;
      }
    }
  }

  .artistTitle {
    border-bottom: 1px solid $--text-color;
    width: 100%;
    padding: 20px 0;
    text-align: center;

    a {
      font-size: 40px;
      color: $--dark-blue;
      font-family: $--Sohne-Buch;
      text-align: center;
      cursor: pointer;

      span {
        color: rgba($color: $--text-color, $alpha: 0.55);
      }

      &:hover {
        color: $--text-color;
      }
    }
  }

  .workWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 33px;
    gap: 20px;
    .work {
      width: 100%;
      margin-bottom: 20px;

      img {
        width: 100%;
        height: 750px;
        object-fit: cover;
        object-position: center;
      }

      h6 {
        font-size: 18px;
        line-height: 23px;
        color: $--text-color;
        font-family: $--Sohne-Buch;
        margin-top: 10px;
      }
    }

    .half {
      width: 49%;
    }
  }
  .navigate {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 60px;
    height: 200px;

    .scrollTop {
      position: absolute;
    }

    a {
      display: flex;
      align-items: flex-start;
      gap: 10px;

      svg {
        position: relative;
        top: -30px;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 7px;
      }
      h6 {
        color: $--dark-blue;
        font-size: 18px;
        font-family: $--Sohne-Breit-Halbfett;
      }
      h5 {
        font-family: $--Sohne-Buch;
        color: rgba($color: $--text-color, $alpha: 0.55);
        font-size: 40px;
        max-width: 405px;
      }
    }

    .left {
      left: 64px;
      position: absolute;
    }

    .right {
      justify-content: flex-end;
      text-align: right;
      position: absolute;
      right: 64px;
    }
    .disabled {
      h6 {
        color: rgba(66, 66, 66, 0.55);
      }

      svg {
        path,
        rect {
          fill: rgba(66, 66, 66, 0.55);
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .artistDetail {
    padding-bottom: 50px;
    .mainTitle {
      width: 100%;
      font-size: 48px;
      font-family: $--Sohne-Leicht;
      line-height: 60px;
      margin-bottom: 80px;
    }

    .wrapper {
      width: 90%;
      .head {
        flex-direction: column;
        border: unset;
        padding-bottom: 20px;
        h1 {
          font-size: 24px;
          width: 100%;
          text-align: center;
          padding-bottom: 15px;
          border-bottom: 1px solid rgba(66, 66, 66, 0.55);
        }
        .subInfo {
          position: absolute;
          top: -50px;
          h3,
          h5 {
            font-size: 12px;
          }
        }
        .rightInfo {
          position: relative;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 15px;
          h3,
          a {
            font-size: 12px;
          }
        }
      }

      .workWrapper {
        .work {
          width: 100%;

          h6 {
            font-size: 12px;
          }
        }

        .half,
        .full {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .navigate {
    padding: unset;
    width: 100% !important;
    align-items: center;
    justify-content: center;
    height: 120px !important;
    button {
      margin-top: 0 !important;
    }
    a {
      flex-direction: column;
      div {
        h6,
        h5 {
          font-size: 12px !important;
          line-height: 15px !important;
        }
      }
    }
    .left {
      left: 20px !important;
    }
    .right {
      flex-direction: column-reverse;
      align-items: flex-end;
      right: 20px !important;
    }
  }
}
