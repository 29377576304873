@font-face {
  font-family: 'Shne Halbfett';
  src: url('../fonts/Sohne-Halbfett.woff2') format('woff2'),
    url('../fonts/Sohne-Halbfett.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Shne Leicht';
  src: url('../fonts/Sohne-Leicht.woff2') format('woff2'),
    url('../fonts/Sohne-Leicht.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Shne Krftig';
  src: url('../fonts/Sohne-Kraftig.woff2') format('woff2'),
    url('../fonts/Sohne-Kraftig.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Shne Buch';
  src: url('../fonts/Sohne-Buch.woff2') format('woff2'),
    url('../fonts/Sohne-Buch.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Shne Breit';
  src: url('../fonts/SohneBreit-Buch.woff2') format('woff2'),
    url('../fonts/SohneBreit-Buch.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Shne Fett';
  src: url('../fonts/Sohne-Fett.woff2') format('woff2'),
    url('../fonts/Sohne-Fett.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Shne Breit Halbfett';
  src: url('../fonts/SohneBreit-Halbfett.woff2') format('woff2'),
    url('../fonts/SohneBreit-Halbfett.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

$--Sohne-Halbfett: 'Shne Halbfett';
$--Sohne-Leicht: 'Shne Leicht';
$--Sohne-Krftig: 'Shne Krftig';
$--Sohne-Buch: 'Shne Buch';
$--Sohne-Breit-Buch: 'Shne Breit';
$--Sohne-Fett: 'Shne Fett';
$--Sohne-Breit-Halbfett: 'Shne Breit Halbfett';

// colors

$--global-grey: #efefef;
$--dark-blue: #4c2dc1;
$--text-color: #424242;

$--max-width: 1200px;

select {
  -webkit-appearance: none;
}
