@import '../../assets/style/variables.module.scss';

.guide {
  background: $--global-grey;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: $--Sohne-Leicht;
    width: 100%;
    font-size: 90px;
    color: #424242;
    margin-top: 50px;
    padding-left: 15px;
    box-sizing: border-box;
    margin-bottom: 60px;
  }

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.guideCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $--text-color;
  }

  img {
    width: 60%;
    height: 612px;
    object-fit: cover;
    object-position: center;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $--text-color;
    width: 40%;
    h6 {
      font-family: $--Sohne-Breit-Halbfett;
      color: $--dark-blue;
      font-size: 18px;
    }
    h2 {
      font-family: $--Sohne-Halbfett;
      font-size: 40px;
      text-align: center;
      line-height: 51px;
      margin-top: 15px;
    }

    .line {
      width: 1px;
      height: 116px;
      background-color: $--text-color;
      margin: 15px 0;
    }

    h4 {
      font-family: $--Sohne-Halbfett;
      color: rgba($color: $--text-color, $alpha: 0.55);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 7px;
      font-size: 18px;

      a {
        color: $--dark-blue;
      }
    }

    h3 {
      font-family: $--Sohne-Buch;
      font-size: 40px;
      text-align: center;
      line-height: 51px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .guide {
    h1 {
      width: 100%;
      font-size: 48px;
      font-family: $--Sohne-Leicht;
      line-height: 60px;
    }

    .wrapper {
      width: 90%;
    }
  }

  .guideCard {
    flex-direction: column;

    .info {
      width: 100%;
      h6 {
        font-size: 12px;
      }
      h2 {
        font-size: 24px;
        margin-top: 10px;
      }
      h4 {
        font-size: 18px;
      }

      h3 {
        font-size: 18px;
        line-height: 23px;
      }
    }
    img {
      width: 100%;
      height: 279px;
      margin-top: 20px;
    }
  }
}
