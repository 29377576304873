@import '../../assets/style/variables.module.scss';

.events {
  background: $--global-grey;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mainTitle {
    font-family: $--Sohne-Leicht;
    width: 100%;
    font-size: 90px;
    color: #424242;
    margin-top: 50px;
    padding-left: 15px;
    box-sizing: border-box;
    margin-bottom: 60px;
  }

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    flex-direction: column;
    align-items: center;

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 42px;

      position: relative;

      h1 {
        font-size: 62px;
        color: $--text-color;
        font-family: $--Sohne-Buch;
      }

      .subInfo {
        position: absolute;
        left: 0;
        display: flex;
        gap: 10px;
        align-items: center;

        h3 {
          font-family: $--Sohne-Breit-Halbfett;
          font-size: 18px;
          color: $--dark-blue;
        }
        h5 {
          font-family: $--Sohne-Breit-Buch;
          color: rgba($color: $--text-color, $alpha: 0.55);
        }

        .divider {
          height: 30px;
          width: 1px;
          background: black;
        }
      }
    }

    .headInfo {
      width: 100%;

      .subHead {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $--text-color;
        border-top: 1px solid $--text-color;
        padding: 35px 100px;
        box-sizing: border-box;
        text-align: center;
        font-size: 40px;
        color: $--dark-blue;
        font-family: $--Sohne-Buch;
      }
    }

    .featuredArtist {
      width: 100%;
      margin-top: 78px;
      position: relative;
      z-index: 1;
      .info {
        h3 {
          color: $--dark-blue;
          font-family: $--Sohne-Buch;
          font-size: 40px;
          padding-bottom: 25px;
          max-width: 560px;
        }
      }

      .artistWrapper {
        margin-top: 45px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 10px;

        a {
          position: relative;
          background: transparent;
          font-family: $--Sohne-Buch;
          color: $--text-color;
          font-size: 40px;
          border-bottom: 1px solid transparent;
          width: 70%;
          text-align: left;
          transition: all 0.5s ease;
          cursor: pointer;
          img {
            position: absolute;
            width: 370px;
            height: 370px;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
            opacity: 0;
            transition: all 0.5s ease;
            right: -100px;
            visibility: hidden;
          }

          &:hover {
            border-bottom: 1px solid $--text-color;
            font-family: $--Sohne-Halbfett;
            img {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .programs {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;

    h4 {
      color: $--dark-blue;
      font-size: 40px;
      font-family: $--Sohne-Buch;
      border-bottom: 1px solid $--text-color;
      padding-bottom: 24px;
      width: 100%;
      max-width: $--max-width;
    }

    .programWrapper {
      width: 100%;
      margin-top: 48px;
      margin-bottom: 54px;
      box-sizing: border-box;

      .slide > div {
        padding-left: 17.5%;
      }

      .slide > div > div {
        display: flex;
        gap: 15px;
      }

      .programCard {
        img {
          height: 470px;
          width: 100%;
          object-fit: cover;
          object-position: center;
          margin-right: 50px;
        }

        .info {
          h6 {
            font-family: $--Sohne-Breit-Buch;
            color: rgba($color: $--text-color, $alpha: 0.55);
            font-size: 24px;
            margin-top: 17px;
          }

          h5 {
            font-family: $--Sohne-Buch;
            color: $--text-color;
            font-size: 40px;
            max-width: 310px;
            line-height: 51px;
            margin-top: 12px;
          }

          button {
            margin-top: 40px;
            color: $--text-color;
            font-family: $--Sohne-Halbfett;
          }
        }
      }
    }
  }

  .maps {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;

    h4 {
      color: $--dark-blue;
      font-size: 40px;
      font-family: $--Sohne-Buch;
      border-bottom: 1px solid $--text-color;
      padding-bottom: 24px;
      width: 100%;
      max-width: $--max-width;
    }

    .mapWrapper {
      width: 100%;
      max-width: $--max-width;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 45px;
      background-color: #3500ff;
    }

    .map {
      mix-blend-mode: luminosity;
    }
  }

  .partners {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;

    h4 {
      color: $--dark-blue;
      font-size: 40px;
      font-family: $--Sohne-Buch;
      border-bottom: 1px solid $--text-color;
      padding-bottom: 24px;
      width: 100%;
      max-width: $--max-width;
    }

    .partnerWrapper {
      width: 100%;
      max-width: $--max-width;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      column-gap: 18px;
      row-gap: 18px;
      margin-top: 40px;
      border-bottom: 1px solid #424242;
      padding-bottom: 40px;
      img {
        width: 100%;
        height: 100%;
        max-height: 220px;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .muralMap {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;

    .head {
      width: 100%;
      display: flex;
      align-items: flex-start;
      max-width: $--max-width;

      label {
        border-bottom: 1px solid $--text-color;
        margin-top: 5px;
        &:last-child {
          margin-left: 15px;
        }

        select {
          font-size: 24px;
          color: $--dark-blue;
          background: transparent;
        }

        span {
          font-size: 24px;
          font-family: $--Sohne-Buch;
          color: rgba($color: $--text-color, $alpha: 0.55);
        }
      }

      h4 {
        color: $--dark-blue;
        font-size: 40px;
        font-family: $--Sohne-Buch;
        padding-bottom: 24px;
        max-width: $--max-width;
        margin-right: 56px;
      }
    }

    .muralMapWrapper {
      margin-top: 20px;
      width: 100%;
      max-width: $--max-width;
      .map {
        width: 100%;
        height: 530px;
      }
    }
  }

  .instagramLink {
    margin-top: 76px;
    font-family: $--Sohne-Buch;
    color: $--dark-blue;
    font-size: 24px;
    border-bottom: 1px solid $--text-color;
    padding-bottom: 76px;
    width: 100%;
    max-width: $--max-width;
    text-align: center;

    span {
      font-family: $--Sohne-Halbfett;
    }
  }

  .team {
    border-top: 1px solid $--text-color;
    width: 100%;
    padding-top: 23px;
    margin-top: 150px;
    margin-bottom: 150px;
    max-width: $--max-width;

    h3 {
      font-family: $--Sohne-Buch;
      font-size: 40px;
      color: $--dark-blue;
      margin-bottom: 77px;
    }

    .teamWrapper {
      display: flex;
      justify-content: space-between;

      .member {
        font-family: $--Sohne-Buch;
        font-size: 24px;
        color: $--text-color;
        line-height: 31px;
        width: 25%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        img {
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
          width: 250px;
          height: 250px;
        }

        h5 {
          margin-top: 40px;
        }
        span {
          color: rgba($color: $--text-color, $alpha: 0.55);
        }
      }
    }
  }

  .navigate {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 64px;
    box-sizing: border-box;
    margin-top: 100px;

    a {
      display: flex;
      align-items: flex-start;
      gap: 10px;

      svg {
        position: relative;
        top: -30px;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 7px;
      }
      h6 {
        color: $--dark-blue;
        font-size: 18px;
        font-family: $--Sohne-Breit-Halbfett;
      }
      h5 {
        font-family: $--Sohne-Buch;
        color: rgba($color: $--text-color, $alpha: 0.55);
        font-size: 40px;
        max-width: 405px;
      }
    }

    .right {
      justify-content: flex-end;
      text-align: right;
    }

    .disabled {
      h6 {
        color: rgba(66, 66, 66, 0.55);
      }

      svg {
        path {
          stroke: rgba(66, 66, 66, 0.55);
        }
      }
    }
  }
}

.marker {
  border-width: 1px solid red;
  padding: 20px;
  min-width: 100px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  h3 {
    font-size: 24px;
    font-family: $--Sohne-Buch;
    color: #3500ff;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    transition: all 0.5s ease;
    position: absolute;
    bottom: -80px;
    right: 0;
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 900px) {
  .events {
    .mainTitle {
      width: 100%;
      font-size: 48px;
      font-family: $--Sohne-Leicht;
      line-height: 60px;
    }

    .wrapper {
      width: 90%;
      .head {
        flex-direction: column-reverse;
        padding-bottom: 20px;
        h1 {
          font-size: 24px;
          margin-top: 20px;
        }
        .subInfo {
          position: relative;
          width: 100%;
          h3,
          h5 {
            font-size: 12px;
          }
        }
      }

      .headInfo {
        .subHead {
          font-size: 18px;
          width: 100%;
          padding: 20px 0;
        }
      }

      .featuredArtist {
        width: 100%;
        margin-top: 20px;

        .info {
          h3 {
            font-size: 24px;
            padding: unset;
          }
        }
        .artistWrapper {
          flex-direction: column;
          margin-top: 20px;

          a {
            width: 100%;
            font-size: 24px;

            img {
              height: 150px;
              width: 150px;
              right: 20px;
            }
          }
        }
      }
    }

    .team {
      width: 90%;
      margin-top: 20px;
      margin-bottom: 20px;
      h3 {
        font-size: 24px;
        margin-bottom: 20px;
      }
      .teamWrapper {
        flex-wrap: wrap;
        .member {
          width: 45%;
          font-size: 24px;

          img {
            width: 100%;
            height: unset;
          }

          h5 {
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            width: 100%;
            margin-top: 20px;

            span {
              font-size: 15px;
            }
          }
        }
      }
    }

    .partners {
      width: 90%;
      h4 {
        font-size: 24px;
        padding-bottom: 20px;
      }
      .partnerWrapper {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .instagramLink {
      padding-bottom: 20px;
      margin-top: 20px;
      width: 90%;
    }

    .muralMap {
      width: 90%;
      margin-top: 20px;

      .muralMapWrapper {
        .map {
          height: 170px;
        }
      }
      .head {
        flex-direction: column;
        h4 {
          font-size: 24px;
          padding-bottom: 20px;
          margin-right: unset;
        }
        .filterWrapper {
          display: flex;

          label {
            margin-left: 0;
            span {
              font-size: 12px;
            }
            select {
              font-size: 12px;
            }
          }
        }
      }
      .festivalHistory {
        width: 100%;
        margin-top: 20px;
        h4 {
          font-size: 24px;
        }

        .content {
          flex-direction: column;
          padding-top: 20px;

          .paragraph {
            h5 {
              font-size: 24px;
            }
            p {
              font-size: 18px;
              line-height: 23px;
            }
          }
          img {
            width: 100%;
            height: 288px;
            object-fit: cover;
            object-position: center;
            margin-top: 20px;
          }
        }
      }
    }
  }
  .navigate {
    padding: unset !important;
    width: 90% !important;
    align-items: center;
    justify-content: center;
    button {
      margin-top: 0 !important;
    }
    a {
      flex-direction: column;
      div {
        h6,
        h5 {
          font-size: 12px !important;
          line-height: 15px !important;
        }
      }
    }
    .right {
      flex-direction: column-reverse;
      align-items: flex-end;
    }
  }
}
