@import "../../assets/style/variables.module.scss";

.about {
  background: $--global-grey;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: $--Sohne-Leicht;
    width: 100%;
    font-size: 90px;
    color: #424242;
    margin-top: 50px;
    padding-left: 15px;
    box-sizing: border-box;
    margin-bottom: 60px;
  }

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      display: flex;
      flex-direction: column;
      width: 100%;

      .block {
        display: flex;
        gap: 15px;
        padding: 35px 0;
        width: 100%;

        &:not(:last-child) {
          border-bottom: 1px solid $--text-color;
        }

        .info {
          font-family: $--Sohne-Buch;
          width: 66%;

          h2 {
            font-size: 40px;
            color: $--dark-blue;
          }

          p {
            font-size: 24px;
            color: $--text-color;
            margin-top: 15px;
            max-width: 700px;
            line-height: 31px;
          }
        }

        img {
          width: 44%;
          height: 758px;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .team {
      border-top: 1px solid $--text-color;
      width: 100%;
      padding-top: 23px;
      margin-top: 150px;
      margin-bottom: 150px;

      h3 {
        font-family: $--Sohne-Buch;
        font-size: 40px;
        color: $--dark-blue;
        margin-bottom: 25px;
      }

      .teamWrapper {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 60px;

        .member {
          font-family: $--Sohne-Buch;
          font-size: 24px;
          color: $--text-color;
          line-height: 31px;
          width: 22%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          img {
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
            width: 250px;
            height: 250px;
          }

          h5 {
            margin-top: 40px;
          }
          span {
            color: rgba($color: $--text-color, $alpha: 0.55);
          }
        }
      }
    }

    .partner {
      width: 100%;
      border-top: 1px solid $--text-color;
      padding-top: 23px;
      h3 {
        font-family: $--Sohne-Buch;
        font-size: 40px;
        color: $--dark-blue;
        margin-bottom: 25px;
      }
      .partnerWrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        .rect {
          width: calc(16.6% - 15px);
          height: 170px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .about {
    h1 {
      width: 100%;
      font-size: 48px;
      font-family: $--Sohne-Leicht;
    }
    .wrapper {
      width: 90%;
      .text {
        .block {
          flex-direction: column;
          width: 100%;

          .info {
            width: 100%;
            h2 {
              font-size: 24px;
            }
            p {
              font-size: 18px;
              line-height: 23px;
            }
          }

          img {
            width: 100%;
          }
        }
      }

      .team {
        margin-top: 10px;
        h3 {
          font-size: 24px;
        }
        .teamWrapper {
          flex-wrap: wrap;
          gap: 18px;
          justify-content: space-between;

          .member {
            width: 45%;

            img {
              width: 100%;
              height: unset;
            }

            h5 {
              font-size: 18px;
              line-height: 20px;
              text-align: center;
              width: 100%;

              span {
                font-size: 15px;
              }
            }
          }
        }
      }

      .partner {
        .partnerWrapper {
          justify-content: space-between;
          .rect {
            width: 83px;
            height: 83px;
          }
        }
      }
    }
  }
}
