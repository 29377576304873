@import '../../assets/style/variables.module.scss';

.header {
  background: $--global-grey;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 15px;
  .hamburgerMenu {
    display: none;
  }
  .active {
    a {
      font-family: $--Sohne-Breit-Halbfett;
    }
  }

  .activeSub {
    font-family: $--Sohne-Halbfett !important;
  }

  ul {
    display: flex;
    gap: 80px;

    li {
      &::before {
        content: '';
        width: 100%;
        height: 50px;
        position: absolute;
        top: 20px;
      }
      a {
        color: $--dark-blue;
        font-family: $--Sohne-Breit-Buch;
        font-size: 24px;
        position: relative;
        display: flex;
        align-items: center;
      }

      .inner {
        position: absolute;
        top: -100px;
        background: $--global-grey;
        padding: 12px 7px;
        opacity: 0;
        transition: opacity 0.5s ease;
        display: flex;
        flex-direction: column;
        gap: 20px;

        a {
          &::before {
            display: none;
          }
        }
      }

      &:hover {
        .inner {
          display: flex;
          opacity: 1;
          top: 60px;
        }
      }

      &:not(:last-child) {
        a::before {
          content: '';
          position: absolute;
          width: 50px;
          height: 1px;
          background: $--dark-blue;
          transform: rotate(-24deg);
          right: -65px;
        }
      }
    }
  }

  select {
    background: transparent;
    color: $--dark-blue;
    font-family: $--Sohne-Fett;
    margin-left: 12px;
    font-size: 12px;
    position: relative;
    z-index: 10;
    -webkit-appearance: none;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 3px;
    .navigate {
      display: flex;
      gap: 20px;
      position: relative;
      align-items: center;
      justify-content: center;
      &::before {
        content: '';
        width: 1px;
        height: 100%;
        background: $--dark-blue;
        position: absolute;
        margin-right: 12px;
      }

      &::after {
        content: '';
        width: 1px;
        height: 100%;
        background: $--dark-blue;
        position: absolute;
        right: -12px;
      }

      a {
        color: $--dark-blue;
        font-family: $--Sohne-Buch;
        font-size: 18px;
      }
    }

    .tr {
      &::before {
        content: '';
        width: 1px;
        height: 100%;
        background: $--dark-blue;
        position: absolute;
        margin-right: -40px;
      }
    }
  }
}

.mobileMenu {
  position: fixed;
  width: 100%;
  height: calc(100% - 70px);
  top: 0;
  left: 0;
  background: #efefef;
  z-index: 101;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    li {
      a {
        font-family: $--Sohne-Breit-Buch;
        font-size: 24px;
        color: $--dark-blue;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }
  }

  select {
    background: transparent;
    color: $--dark-blue;
    font-family: $--Sohne-Fett;
    margin-left: 12px;
    font-size: 12px;
  }

  .bottom {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-top: 100px;
    .navigate {
      display: flex;
      gap: 20px;
      position: relative;
      align-items: center;
      justify-content: center;
      &::before {
        content: '';
        width: 1px;
        height: 100%;
        background: $--dark-blue;
        position: absolute;
        margin-right: 12px;
      }

      &::after {
        content: '';
        width: 1px;
        height: 100%;
        background: $--dark-blue;
        position: absolute;
        right: -12px;
      }

      a {
        color: $--dark-blue;
        font-family: $--Sohne-Buch;
        font-size: 18px;
      }
    }

    .tr {
      &::before {
        content: '';
        width: 1px;
        height: 100%;
        background: $--dark-blue;
        position: absolute;
        margin-right: -40px !important;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .header {
    position: fixed;
    top: 0;
    z-index: 102;

    .hamburgerMenu {
      display: flex;
      background: transparent;
    }

    .logo {
      width: 168px;

      svg {
        width: 100%;
      }
    }

    ul {
      display: none;
    }

    .right {
      display: none;
    }
  }
}
