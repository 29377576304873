@import '../../assets/style/variables.module.scss';

.contact {
  background: $--global-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;

  h1 {
    font-family: $--Sohne-Leicht;
    width: 100%;
    font-size: 90px;
    color: #424242;
    margin-top: 50px;
    padding-left: 15px;
    box-sizing: border-box;
    margin-bottom: 60px;
  }

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    justify-content: space-between;
    gap: 100px;
    padding-bottom: 20px;

    .column {
      width: 50%;
      justify-content: space-between;
      min-height: 75vh;

      .top {
        display: flex;
        flex-direction: column;
        height: 90%;
      }

      h2 {
        color: $--text-color;
        font-size: 40px;
        font-family: $--Sohne-Buch;
      }

      .mail {
        color: $--dark-blue;
        font-family: $--Sohne-Halbfett;
        font-size: 24px;
        padding-bottom: 18px;
        margin-top: 16px;

        &:last-child {
          border-bottom: 1px solid $--text-color;
        }

        span {
          font-family: $--Sohne-Buch;
          color: rgba($color: $--text-color, $alpha: 0.55);
        }
      }

      .instagram {
        color: $--dark-blue;
        font-family: $--Sohne-Halbfett;
        font-size: 24px;
        border-bottom: 1px solid $--text-color;
        border-top: 1px solid $--text-color;
        padding: 15px 0;
        margin-bottom: 20px;
        padding-left: 20px;
        position: relative;
        display: flex;
        align-items: center;

        &::before {
          content: '';

          background: $--dark-blue;
          width: 10px;
          height: 10px;
          position: absolute;
          left: 0;
          border-radius: 50%;
        }
      }

      h6 {
        color: rgba($color: $--text-color, $alpha: 0.55);
        font-family: $--Sohne-Buch;
        font-size: 24px;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 24px;
        label {
          h4 {
            font-size: 24px;
            color: $--text-color;
            font-family: $--Sohne-Buch;
          }

          input,
          textarea {
            margin-top: 30px;
            width: 100%;
            background: transparent;
            font-family: $--Sohne-Buch;
            border-bottom: 1px solid $--text-color;
            font-size: 24px;
            resize: none;

            &::placeholder {
              color: rgba($color: $--text-color, $alpha: 0.55);
            }
          }

          input {
            padding-bottom: 15px;
          }

          textarea {
            min-height: 150px;
          }
        }

        h6 {
          font-family: $--Sohne-Buch;
        }

        .error {
          color: rgb(238, 103, 103);
        }

        .success {
          color: rgb(30, 168, 46);
        }

        button {
          color: $--dark-blue;
          text-align: left;
          font-size: 40px;
          font-family: $--Sohne-Buch;
          background: transparent;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .contact {
    h1 {
      width: 100%;
      font-size: 48px;
      font-family: $--Sohne-Leicht;
    }

    .wrapper {
      flex-direction: column;
      gap: 50px;
      width: 90%;
      .column {
        width: 100%;
        min-height: unset;
        h6 {
          font-size: 18px;
          margin-top: 20px;
        }
        h2 {
          font-size: 24px;
        }
        .top {
          h2 {
            font-size: 24px;
          }
          a {
            font-size: 18px;
          }
        }

        form {
          label {
            h4 {
              font-size: 18px;
            }
            input {
              font-size: 18px;
              margin-top: 20px;
            }
          }

          button {
            font-size: 24px;
          }
        }
      }
    }
  }
}
