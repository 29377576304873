@import '../../assets/style/variables.module.scss';

.projectDetail {
  background: $--global-grey;
  display: flex;
  flex-direction: column;
  align-items: center;

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
    }

    .info {
      display: flex;
      width: 100%;
      margin-bottom: 30px;

      ul {
        display: flex;
        flex-direction: column;
        gap: 5px;
        li {
          font-size: 18px;
          font-family: $--Sohne-Halbfett;
          color: $--text-color;
          span {
            font-family: $--Sohne-Buch;
            color: rgba($color: $--text-color, $alpha: 0.55);
          }
        }
      }

      .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .shareButton {
          font-family: $--Sohne-Buch;
          font-size: 18px;
          color: $--dark-blue;
          position: absolute;
          right: 0;
          top: 0;
          background: transparent;
          cursor: pointer;
        }

        .shareWrapper {
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          flex-direction: column;
          gap: 7px;
          align-items: flex-end;

          button {
            font-family: $--Sohne-Buch !important;
            font-size: 18px !important;
            color: $--dark-blue !important;
          }
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 50%;
      }

      h3 {
        font-size: 40px;
        font-family: $--Sohne-Buch;
        color: $--text-color;
        max-width: 310px;
      }

      h4 {
        font-family: $--Sohne-Breit-Halbfett;
        font-size: 18px;
        color: #424242;

        .project {
          position: relative;
          margin-right: 20px;
          color: $--dark-blue;
          &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            background: black;
            right: -10px;
          }
        }

        .mural {
          font-family: $--Sohne-Breit-Buch;
          color: rgba(66, 66, 66, 0.55);
        }

        svg {
          margin: 0 8px;
          path {
            stroke: $--dark-blue;
          }
        }
      }
    }

    .head {
      width: 100%;
      img {
        width: 100%;
      }
      p {
        font-family: $--Sohne-Buch;
        font-size: 24px;
        max-width: 760px;
        color: $--text-color;
        margin: 18px 0;
      }
    }

    .contentWrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 18px 0;
    }

    .contentInfo {
      display: flex;
      flex-direction: column;
      font-family: $--Sohne-Buch;
      font-size: 18px;
      color: $--dark-blue;
      gap: 18px;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 18px;
      display: flex;
      flex-direction: column;
      font-family: $--Sohne-Buch;
      font-size: 18px;
      color: $--text-color;
      width: 75%;
      p {
        width: 100%;
        font-size: 24px;
        color: $--text-color;
        line-height: 31px;
      }

      img {
        width: 100%;
      }
      .half {
        width: calc(50% - 9px);
      }
    }

    .imageContent {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 20px;

      .full {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
      .half {
        width: 49%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .navigate {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 60px;
    height: 200px;

    .scrollTop {
      position: absolute;
    }

    a {
      display: flex;
      align-items: flex-start;
      gap: 10px;

      svg {
        position: relative;
        top: -30px;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 7px;
      }
      h6 {
        color: $--dark-blue;
        font-size: 18px;
        font-family: $--Sohne-Breit-Halbfett;
      }
      h5 {
        font-family: $--Sohne-Buch;
        color: rgba($color: $--text-color, $alpha: 0.55);
        font-size: 40px;
        max-width: 405px;
      }
    }

    .left {
      left: 64px;
      position: absolute;
    }

    .right {
      justify-content: flex-end;
      text-align: right;
      position: absolute;
      right: 64px;
    }
    .disabled {
      h6 {
        color: rgba(66, 66, 66, 0.55);
      }

      svg {
        path,
        rect {
          fill: rgba(66, 66, 66, 0.55);
        }
      }
    }
  }

  h1 {
    font-family: $--Sohne-Leicht;
    width: 100%;
    font-size: 90px;
    color: #424242;
    margin-top: 50px;
    padding-left: 15px;
    box-sizing: border-box;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 900px) {
  .projectDetail {
    padding-bottom: 50px;
    h1 {
      width: 100%;
      font-size: 48px;
      font-family: $--Sohne-Leicht;
      line-height: 60px;
    }

    .wrapper {
      width: 90%;
      .info {
        flex-direction: column;
        gap: 18px;
        .left,
        .right {
          width: 100%;
        }
        h4 {
          font-size: 12px;
        }
        h3 {
          font-size: 24px;
          line-height: 31px;
        }

        .right {
          align-items: flex-start;
          ul {
            li {
              font-size: 12px;
            }
          }
          .shareButton {
            font-size: 12px;
            margin-top: 12px;
            position: relative;
            right: unset;
            top: unset;
          }
          .shareWrapper {
            margin-top: 15px;
            position: relative;
            align-items: flex-start;
            gap: 5px;

            button {
              font-size: 12px !important;
            }
          }
        }
      }

      .head {
        p {
          font-size: 18px;
          line-height: 23px;
        }
      }

      .contentWrapper {
        flex-direction: column;
      }

      .contentInfo {
        svg {
          display: none;
        }
      }

      .content {
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        p {
          font-size: 18px;
          line-height: 23px;
          width: 100%;
        }
        .half {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }

      .imageContent {
        .half {
          width: 100%;
        }
      }
    }
    .navigate {
      padding: unset;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 120px;
      button {
        margin-top: 0 !important;
      }
      a {
        flex-direction: column;
        div {
          h6,
          h5 {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
      .left {
        left: 20px;
      }
      .right {
        flex-direction: column-reverse;
        align-items: flex-end;
        right: 20px;
      }
    }
  }
}
