@import '../../assets/style/variables.module.scss';

.slider {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  box-sizing: border-box;

  .slideItem {
    height: 100vh;
    position: relative;
    &::before {
      content: '';
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.54) 30.09%,
        rgba(66, 66, 66, 0) 100%
      );
      width: 100%;
      height: 350px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    .shadowClick {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 10;
    }

    .dotWrapper {
      display: flex;
      gap: 5px;
      margin-top: 20px;

      .dot {
        width: 10px;
        height: 10px;
        background: white;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .rightArrow {
      position: absolute;
      right: 50px;
      bottom: 130px;
      z-index: 20;
      background: transparent;
      cursor: pointer;
    }

    .info {
      position: absolute;
      left: 50px;
      bottom: 130px;
      z-index: 5;
      display: flex;
      flex-direction: column;
      z-index: 2;

      .mainTitle {
        font-family: $--Sohne-Krftig;
        font-size: 90px;
        color: white;
        line-height: 98.4%;
        max-width: 900px;
        letter-spacing: 0.02em;
        margin-top: 20px;
        margin-bottom: 13px;
      }

      .readMore {
        font-family: $--Sohne-Halbfett;
        color: white;
        font-size: 18px;
      }

      .topTitle {
        font-size: 18px;
        color: white;
        font-family: $--Sohne-Breit-Halbfett;
        .project {
          font-family: $--Sohne-Breit-Halbfett;
          position: relative;
          margin-right: 20px;
        }

        .line {
          &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            background: white;
            right: -10px;
          }
        }

        .mural {
          font-family: $--Sohne-Breit-Buch;
        }

        svg {
          margin: 0 10px;
        }
      }
    }

    img {
      height: 100% !important;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

@media only screen and (max-width: 900px) {
  .slider {
    height: 100%;
    .rightArrow {
      display: none;
    }

    .slideItem {
      display: flex !important;
      align-items: flex-end;
      justify-content: flex-start;
      height: -webkit-fill-available;
      min-height: -webkit-fill-available;

      img {
        height: 100vh !important;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .info {
        left: unset;
        bottom: unset;
        padding: 53px 14px;
        width: 100%;
        box-sizing: border-box;

        .topTitle {
          font-size: 12px;
          .project,
          .mural {
            font-size: 12px;
          }
        }
        .mainTitle {
          font-size: 24px;
          font-family: $--Sohne-Buch;
          line-height: 31px;
          max-width: 200px;
        }

        .readMore {
          font-size: 12px;
        }

        .dotWrapper {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
