@import '../../assets/style/variables.module.scss';

.events {
  background: $--global-grey;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mainTitle {
    font-family: $--Sohne-Leicht;
    width: 100%;
    font-size: 90px;
    color: #424242;
    margin-top: 50px;
    padding-left: 15px;
    box-sizing: border-box;
    margin-bottom: 60px;
  }

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    flex-direction: column;
    align-items: center;

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 42px;

      position: relative;

      h1 {
        font-size: 48px;
        color: $--text-color;
        font-family: $--Sohne-Halbfett;
        border-bottom: 1px solid $--text-color;
        width: 100%;
        text-align: center;
        padding-bottom: 30px;
      }

      .filterYear {
        position: absolute;
        right: 0;
        font-size: 24px;

        span {
          font-family: $--Sohne-Buch;
          color: rgba($color: $--text-color, $alpha: 0.55);
        }

        select {
          font-family: $--Sohne-Halbfett;
          color: $--dark-blue;
          background: transparent;
        }
      }
    }

    .headInfo {
      width: 100%;

      .subHead {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $--text-color;
        border-top: 1px solid $--text-color;
        padding: 35px 100px;
        box-sizing: border-box;

        button {
          font-family: $--Sohne-Buch;
          font-size: 40px;
          color: $--text-color;
          display: flex;
          align-items: center;
          gap: 10px;
          background: transparent;
          cursor: pointer;

          svg {
            transform: rotate(180deg);
          }
        }

        .active {
          font-family: $--Sohne-Halbfett;
        }
      }
    }

    .featuredArtist {
      width: 100%;
      margin-top: 78px;
      position: relative;
      z-index: 1;
      .info {
        h3 {
          color: $--dark-blue;
          font-family: $--Sohne-Buch;
          font-size: 40px;
          border-bottom: 1px solid $--text-color;
          padding-bottom: 25px;
          max-width: 560px;
        }
      }

      .artistWrapper {
        margin-top: 45px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        button {
          position: relative;
          background: transparent;
          font-family: $--Sohne-Buch;
          color: $--text-color;
          font-size: 40px;
          border-bottom: 1px solid transparent;
          width: 70%;
          text-align: left;
          transition: all 0.5s ease;
          cursor: pointer;
          img {
            position: absolute;
            width: 370px;
            height: 370px;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
            opacity: 0;
            transition: all 0.5s ease;
            right: -100px;
            visibility: hidden;
          }

          &:hover {
            border-bottom: 1px solid $--text-color;
            font-family: $--Sohne-Halbfett;
            img {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .programs {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .programHead {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: $--max-width;

      h4 {
        color: $--dark-blue;
        font-size: 40px;
        font-family: $--Sohne-Buch;
        padding-bottom: 20px;
        width: 100%;
        max-width: $--max-width;
      }
    }

    .programWrapper {
      width: 100%;
      margin-top: 48px;
      margin-bottom: 54px;
      box-sizing: border-box;
      position: relative;

      .sliderArrow {
        display: flex;
        width: 80%;
        justify-content: space-between;
        position: absolute;
        z-index: 10;
        top: 30%;
        left: 10.5%;

        button {
          cursor: pointer;
          background: $--dark-blue;
          padding: 10px 10px 6px 10px;
          box-sizing: border-box;
        }

        .sliderLeft {
          svg {
            transform: rotate(180deg);
          }
        }

        .deactive {
          svg {
            rect,
            path {
              fill: rgba(66, 66, 66, 0.55);
            }
          }
        }
      }

      .slide > div {
      }

      .slide > div > div {
        // padding-left: 17.5%;
        display: flex;
        gap: 15px;
      }

      .slide > div > div > div {
        &:first-child {
          margin-left: 7%;
        }
      }

      .programCard {
        img {
          height: 470px;
          width: 100%;
          object-fit: cover;
          object-position: center;
          margin-right: 50px;
        }

        .info {
          h6 {
            font-family: $--Sohne-Buch;
            color: $--dark-blue;
            border-bottom: 1px solid $--text-color;
            font-size: 40px;
            margin-top: 17px;
          }

          h5 {
            font-family: $--Sohne-Buch;
            color: $--text-color;
            font-size: 40px;
            line-height: 51px;
            margin-top: 12px;
          }

          button {
            margin-top: 20px;
            color: $--dark-blue;
            font-family: $--Sohne-Halbfett;
            display: flex;
            align-items: center;
            background: transparent;
            svg {
              margin-top: -15px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .artists {
    width: 100%;
    max-width: $--max-width;
    border-bottom: 1px solid $--text-color;
    border-top: 1px solid $--text-color;
    padding: 61px 0;

    h4 {
      color: $--dark-blue;
      font-size: 40px;
      font-family: $--Sohne-Buch;
      padding-bottom: 20px;
      width: 100%;
      max-width: $--max-width;
    }

    .artistWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      a {
        width: 32%;
        font-size: 40px;
        font-family: $--Sohne-Buch;
        color: $--text-color;
        transition: all 0.3s ease;

        &:hover {
          color: $--dark-blue;
        }
      }
    }
  }

  .festivalHistory {
    margin-top: 70px;
    h4 {
      color: $--text-color;
      font-size: 40px;
      font-family: $--Sohne-Buch;
      padding-bottom: 20px;
      width: 100%;
      border-top: 1px solid $--text-color;
      padding-top: 20px;
    }

    .content {
      width: 100%;
      display: flex;
      padding-top: 70px;
      max-width: $--max-width;

      .paragraph {
        h5 {
          color: $--dark-blue;
          font-size: 40px;
          font-family: $--Sohne-Buch;
        }

        p {
          font-family: $--Sohne-Buch;
          font-size: 24px;
          color: $--text-color;
          line-height: 31px;
          margin-top: 15px;
          margin-right: 30px;
        }
      }

      img {
        width: 463px;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .maps {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;

    h4 {
      color: $--dark-blue;
      font-size: 40px;
      font-family: $--Sohne-Buch;
      border-bottom: 1px solid $--text-color;
      padding-bottom: 24px;
      width: 100%;
      max-width: $--max-width;
    }

    .mapWrapper {
      width: 100%;
      max-width: $--max-width;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 45px;
      background-color: #3500ff;
    }

    .map {
      mix-blend-mode: luminosity;
    }
  }

  .partners {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;

    h4 {
      color: $--dark-blue;
      font-size: 40px;
      font-family: $--Sohne-Buch;
      border-bottom: 1px solid $--text-color;
      padding-bottom: 24px;
      width: 100%;
      max-width: $--max-width;
    }

    .partnerWrapper {
      width: 100%;
      max-width: $--max-width;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 18px;
      row-gap: 18px;
      margin-top: 40px;
      border-bottom: 1px solid #424242;
      padding-bottom: 40px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .muralMap {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;

    .head {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: $--max-width;

      label {
        border-bottom: 1px solid $--text-color;
        margin-top: 5px;
        &:last-child {
          margin-left: 15px;
        }

        select {
          font-size: 24px;
          color: $--dark-blue;
          background: transparent;
        }

        span {
          font-size: 24px;
          font-family: $--Sohne-Buch;
          color: rgba($color: $--text-color, $alpha: 0.55);
        }
      }

      h4 {
        color: $--dark-blue;
        font-size: 40px;
        font-family: $--Sohne-Buch;
        padding-bottom: 24px;
        max-width: $--max-width;
        margin-right: 56px;
      }
    }

    .muralMapWrapper {
      margin-top: 20px;
      width: 100%;
      max-width: $--max-width;
      .map {
        width: 100%;
        height: 530px;
      }
    }
  }
}

.marker {
  border-width: 1px solid red;
  padding: 20px;
  width: 35px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    width: 35px;
    height: 50px;
  }
  .markerCard {
    opacity: 0;
    position: absolute;
    bottom: -110px;
    border: 1px solid $--dark-blue;
    padding: 15px;
    background: white;
    display: flex;
    gap: 10px;

    img {
      width: 137px;
      height: 90px;
      object-fit: cover;
      object-position: center;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      padding-left: 10px;

      &::before {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        left: 0;
        background: $--dark-blue;
      }

      h5 {
        color: $--dark-blue;
        font-family: $--Sohne-Halbfett;
        font-size: 18px;
      }
      h6 {
        color: $--text-color;
        font-family: $--Sohne-Buch;
        font-size: 16px;
      }
      a {
        color: $--text-color;
        font-family: $--Sohne-Buch;
        font-size: 16px;
        min-width: 116px;
      }

      .markerLocation {
        position: relative;
        padding-top: 5px;

        &::before {
          content: '';
          width: 100%;
          height: 1px;
          background: $--dark-blue;
          position: absolute;
          top: 0;
        }
      }
    }
  }

  h3 {
    font-size: 18px;
    font-family: $--Sohne-Buch;
    color: #3500ff;
  }

  // img {
  //   width: 100px;
  //   height: 100px;
  //   object-fit: cover;
  //   object-position: center;
  //   opacity: 0;
  //   transition: all 0.5s ease;
  //   position: absolute;
  //   bottom: -80px;
  //   right: 0;
  // }

  &:hover {
    z-index: 100;
    img {
      opacity: 1;
    }
    .markerCard {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 900px) {
  .events {
    .mainTitle {
      width: 100%;
      font-size: 48px;
      font-family: $--Sohne-Leicht;
      line-height: 60px;
    }

    .wrapper {
      width: 90%;
      .head {
        h1 {
          font-size: 24px;
        }
      }
    }

    .slide > div > div {
      // padding-left: 17.5%;
      gap: 0px !important;
    }

    .slide > div > div > div {
      display: flex;
      align-items: 'center' !important;
      justify-content: 'center' !important;
      &:first-child {
        margin-left: 0 !important;
      }
    }

    .slide > div > div > div > div {
      display: flex;
      align-items: center;
      justify-content: 'center' !important;

      width: 100%;
    }

    .programs {
      .programHead {
        width: 90%;
        align-items: center;
        h4 {
          width: 90%;
          font-size: 24px;
          padding-bottom: 0;
        }

        button {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .programWrapper {
        margin-top: 20px;

        .sliderArrow {
          display: none;
        }

        .slide > div {
          padding-left: 0%;
        }

        .programCard {
          width: 100% !important;
          align-items: center;
          padding: 20px;

          img {
            height: 300px;
          }
        }
      }
    }

    .artists {
      width: 90%;
      padding: 20px 0;
      h4 {
        font-size: 24px;
      }
      .artistWrapper {
        flex-direction: column;

        a {
          width: 100%;
          font-size: 24px;
        }
      }
    }

    .muralMap {
      width: 90%;
      margin-top: 20px;

      .muralMapWrapper {
        .map {
          height: 80vh;
        }
      }
      .head {
        flex-direction: column;
        h4 {
          font-size: 24px;
          padding-bottom: 20px;
          margin-right: unset;
        }
        .filterWrapper {
          display: flex;

          label {
            span {
              font-size: 12px;
            }
            select {
              font-size: 12px;
            }
          }
        }
      }
      .festivalHistory {
        width: 100%;
        margin-top: 20px;
        h4 {
          font-size: 24px;
        }

        .content {
          flex-direction: column;
          padding-top: 20px;

          .paragraph {
            h5 {
              font-size: 24px;
            }
            p {
              font-size: 18px;
              line-height: 23px;
            }
          }
          img {
            width: 100%;
            max-height: 600px;
            object-fit: cover;
            object-position: center;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
