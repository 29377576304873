@import '../../assets/style/variables.module.scss';

.bannerTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;

  h5 {
    font-family: $--Sohne-Breit-Halbfett;
    color: $--text-color;

    span {
      color: rgba($color: $--text-color, $alpha: 0.55);
    }
  }

  svg {
    transform: rotate(90deg);
    width: 70px;
    height: 70px;

    path {
      stroke: $--dark-blue;
    }
  }
}

.banner {
  width: 100%;
  height: 470px;
  background: $--dark-blue;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.more {
  margin-top: 20px;
  margin-bottom: 60px;
  font-family: $--Sohne-Halbfett;
  font-size: 18px;
  color: $--dark-blue;
}

@media only screen and (max-width: 900px) {
  .banner {
    height: 120px;
  }

  .more {
    font-size: 12px;
  }
}
