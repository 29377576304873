@import '../../assets/style/variables.module.scss';

.preloader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
